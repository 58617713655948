export const accrodion_array = [
    {
        heading: "I have questions about narcolepsy.",
        customTagTitle: "I have questions about narcolepsy",
        innerAccordion: [
            {
                heading:
                    "How is narcolepsy type 1 different from narcolepsy type 2?",
                content: `Narcolepsy with cataplexy is usually called narcolepsy type 1. Narcolepsy without cataplexy is usually called narcolepsy type 2. All people living with narcolepsy have excessive daytime sleepiness (EDS), and nearly 2 out of 3 people with narcolepsy may have cataplexy. <a href="/living-with-narcolepsy">Find out more about narcolepsy</a>.`,
                customTagTitleSub:
                    "How is narcolepsy type 1 different from narcolepsy type 2",
            },
            {
                heading: `What type of doctor can diagnose or treat narcolepsy?`,
                content: `There are many types of healthcare providers that can help manage your narcolepsy. Some of these providers include sleep specialists, neurologists, psychiatrists, pulmonologists, primary care doctors (PCPs), nurse practitioners, and physician assistants.`,
                customTagTitleSub:
                    "What type of doctor can diagnose or treat narcolepsy",
            },
            {
                heading: `What is excessive daytime sleepiness (EDS)?`,
                content: `The main symptom of narcolepsy is excessive daytime sleepiness (EDS), which is the persistent feeling of tiredness. All people with narcolepsy have EDS, but each person may experience their EDS differently. <a href="/living-with-narcolepsy">Learn more about how people may experience EDS</a>.`,
                customTagTitleSub:
                    "What is excessive daytime sleepiness (EDS)?",
            },
            {
                heading: `What is cataplexy?`,
                content: `Cataplexy is the sudden onset of weak or paralyzed muscles and is usually brought on by strong emotions or certain situations. Nearly 2 out of 3 people with narcolepsy may also have cataplexy, but it may be difficult to recognize. <a href="/living-with-narcolepsy">Learn more about what cataplexy can feel like</a>.`,
                customTagTitleSub: "What is cataplexy?",
            },
            {
                heading: `How do I know if it is cataplexy?`,
                content: `Cataplexy can cause people with narcolepsy to collapse completely, but more often it affects specific areas of the body. It’s not always obvious that the experiences of cataplexy are related to narcolepsy. Talk to your healthcare provider if you think you may have cataplexy. <a href="/living-with-narcolepsy">Learn more about what cataplexy can feel like</a>.`,
                customTagTitleSub: "How do I know if it is cataplexy?",
            },
        ],
    },
    {
        heading: "What is WAKIX?",
        customTagTitle: "What is WAKIX?",
        innerAccordion: [
            {
                heading: `What are the FDA-approved uses of WAKIX?`,
                content: `<p>WAKIX is FDA approved to treat excessive daytime sleepiness (EDS) or cataplexy in adults with narcolepsy and to treat EDS in children 6 years of age and older with narcolepsy.</p>
        <p class='top-spacer'>It is not known if WAKIX is safe and effective to treat excessive daytime sleepiness in children under 6 years of age with narcolepsy or to treat cataplexy in people under 18 years of age with narcolepsy. <a href="/about-wakix">Learn more about WAKIX</a>.</p>`,
                customTagTitleSub: "What are the FDA-approved uses of WAKIX?",
            },
            {
                heading: `Is WAKIX a new medication for use in narcolepsy?`,
                content: `WAKIX was approved by the FDA for the treatment of excessive daytime sleepiness (EDS) in adults with narcolepsy in August 2019 and for the treatment of cataplexy in adults with narcolepsy in October 2020. In June 2024, WAKIX was approved by the FDA for the treatment of EDS in children 6 years of age and older with narcolepsy. <a href="/about-wakix">Learn more about WAKIX</a>.`,
                customTagTitleSub:
                    "Is WAKIX a new medication for use in narcolepsy?",
            },
            {
                heading: `How is WAKIX thought to work?`,
                content: `WAKIX is a first-of-its-kind medication that is thought to work differently. While the way WAKIX works is not fully understood, it is thought that WAKIX reduces excessive daytime sleepiness (EDS) in people 6 years of age and older with narcolepsy or cataplexy in adults with narcolepsy by increasing histamine levels in the brain. <a href="/about-wakix/how-does-wakix-work">Watch a video of how WAKIX is thought to work</a>.`,
                customTagTitleSub: "How is WAKIX thought to work?",
            },
            {
                heading: `Is WAKIX a controlled substance?`,
                content: `WAKIX is the only FDA-approved treatment for people with narcolepsy that is not a controlled substance. A controlled substance is a drug or chemical that is regulated by the government based on its potential for abuse and dependence. In a clinical study in adults, WAKIX did not show potential for abuse, similar to placebo (sugar pill).`,
                customTagTitleSub: "Is WAKIX a controlled substance?",
            },
            {
                heading: `Is WAKIX a stimulant?`,
                content: `WAKIX is not a stimulant.`,
                customTagTitleSub: "Is WAKIX a stimulant?",
            },
        ],
    },
    {
        heading:
            "What should I know about getting a WAKIX prescription and available WAKIX support programs?",
        customTagTitle:
            "What should I know about getting a WAKIX prescription and available WAKIX support programs?",
        innerAccordion: [
            {
                heading: `How will I get a WAKIX prescription?`,
                content: `After WAKIX is prescribed, the prescription is filled through a Specialty Pharmacy, which may be different than how you’ve received other prescription medications. The Specialty Pharmacy will ship WAKIX to the location of your choice. A Specialty Pharmacy is different from a traditional pharmacy, or retail pharmacy. Specialty Pharmacies are often used for diseases or disorders that have smaller groups of people and where additional financial support might be needed. <a href="/wakix-for-you-support-program">Learn about support available after WAKIX has been prescribed</a>.`,
                customTagTitleSub: "How will I get a WAKIX prescription?",
            },
            {
                heading: `How much does WAKIX cost? Is there financial support available for patients? Is there a WAKIX patient support program available?`,
                content: `Eligible patients may pay as little as a $0 copay on WAKIX prescriptions with the <strong class="italic blue">WAKIX for You</strong> Program. This offer is valid only for patients who have commercial (nongovernment-funded) insurance. Additional terms and conditions apply. Once WAKIX is prescribed, you have the option to sign up for the <strong class="italic blue">WAKIX for You</strong> patient support program. This program provides individual reimbursement and financial support based on your specific needs and eligibility. Contact <strong class="italic blue">WAKIX for You</strong> at <nobr>1-855-WAKIX4U</nobr> <nobr>(1-855-925-4948)</nobr> Monday through Friday, 8 AM to 8 PM Eastern Time. <a href="/wakix-for-you-support-program">Learn more about <span class='italic blue bold'>WAKIX for You</span></a>.`,
                customTagTitleSub:
                    "How much does WAKIX cost? Is there financial support available for patients? Is there a WAKIX patient support program available?",
            },
        ],
    },
    {
        heading: "What should I know about taking WAKIX?",
        customTagTitle: "What Should I know about taking WAKIX?",
        innerAccordion: [
            {
                heading: `Who should not take WAKIX?`,
                content: `Do not take WAKIX if you are allergic to pitolisant or any ingredient in WAKIX, or if you have severe liver disease. WAKIX may not be right for patients with certain heart or other medical conditions or taking certain medications. Talk to your healthcare provider for more information.`,
                customTagTitleSub: "Who should not take WAKIX?",
            },
            {
                heading: `What are the most common WAKIX side effects?`,
                content: `In the clinical trials of adults with narcolepsy, the most common side effects of WAKIX that were at least twice the rate of placebo were insomnia, nausea, and anxiety.<br/><br/>
        Other side effects of WAKIX in the adult clinical trials included headache, upper respiratory tract infection, musculoskeletal pain, increased heart rate, hallucinations, irritability, abdominal pain, sleep disturbance, and decreased appetite.<br/><br/>
        In the clinical trial of patients 6-17 years old with narcolepsy, the most common side effects of WAKIX were headache and insomnia. Overall, the side effects seen in the clinical trial of WAKIX in patients 6-17 years old were similar to those seen in the adult clinical trials.<br/><br/>
        These are not all of the possible side effects of WAKIX. Tell your healthcare provider about any side effect that is bothersome or that does not go away.`,
                customTagTitleSub:
                    "What are the most common WAKIX side effects?",
            },
            {
                heading: `How long does it take for WAKIX to work?`,
                content: `Everyone responds to medication differently. It's important to know that WAKIX may take some time to work, and for some people, it may take up to 8 weeks to achieve a response. Talk to your healthcare provider about what to expect when taking WAKIX. <a href="/starting-wakix/what-to-expect">Learn more about what to expect when taking WAKIX</a>.`,
                customTagTitleSub: "How long does it take for WAKIX to work?",
            },
            {
                heading: `What does it mean that WAKIX will be titrated?`,
                content: `Titration means that your healthcare provider will start WAKIX at a lower dose and increase it each week to find the dose that’s right. WAKIX should always be taken exactly as prescribed. <a href="/starting-wakix">Learn more about taking WAKIX</a>.`,
                customTagTitleSub:
                    "What does it mean that WAKIX will be titrated?",
            },
            {
                heading: `How should WAKIX be taken?`,
                content: `WAKIX should always be taken exactly as prescribed. WAKIX tablets should be taken once daily in the morning, as soon as you wake up. You only need to take WAKIX once each day in the morning. Avoid taking WAKIX later in the day. If you miss a dose, take the next dose the following morning, as soon as you wake up. WAKIX tablets should not be crushed or chewed. If you have any questions about when to take WAKIX, talk with your healthcare provider.`,
                customTagTitleSub: "How should WAKIX be taken?",
            },
            {
                heading: `What should I do if a dose of WAKIX is missed?`,
                content: `If you miss a dose, take the next dose the following morning, as soon as you wake up. If you have any more questions, talk with your healthcare provider.`,
                customTagTitleSub:
                    "What should I do if a dose of WAKIX is missed?",
            },
            {
                heading: `Can antihistamines be taken with WAKIX?`,
                content: `Certain antihistamines can prevent histamine from working in the brain. These medications may reduce the effectiveness of WAKIX and should be avoided.<br/><br/>
        Your healthcare provider may be able to provide suggestions about what antihistamines or alternative treatments can be taken with WAKIX and which antihistamines should be avoided. Tell your healthcare provider about all the medications you take or plan to take, including prescription and over-the-counter medications, vitamins, and herbal supplements. <a href="/about-wakix/how-does-wakix-work">Learn what role histamine plays in the brain</a>.`,
                customTagTitleSub: "Can antihistamines be taken with WAKIX?",
            },
            {
                heading: `Can WAKIX be taken when pregnant or planning to become pregnant?`,
                content: `Talk to your healthcare provider if you are pregnant or planning to become pregnant. WAKIX has a voluntary registry that helps researchers learn more about the safety of WAKIX when used during pregnancy. People who are pregnant or become pregnant while taking WAKIX are encouraged to enroll in the WAKIX Pregnancy Registry. For more information about the registry or to enroll, call <nobr>1-800-833-7460.</nobr>`,
                customTagTitleSub:
                    "Can WAKIX be taken when pregnant or planning to become pregnant?",
            },
            {
                heading: `Can WAKIX be taken with birth control? Does WAKIX have an effect on birth control?`,
                content: `Hormonal birth control methods may not work while taking WAKIX. Use an alternative non-hormonal birth control method during treatment with WAKIX and for at least 21 days after stopping WAKIX. Talk to your healthcare provider for more information.`,
                customTagTitleSub:
                    "Can WAKIX be taken with birth control? Does WAKIX have an effect on birth control?",
            },
        ],
    },
];
