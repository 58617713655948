import * as React from "react";
import HcpLayout from "../../../components/hcp/layout";
import { Seo } from "../../../components/seo";
// import useDeviceSize from "../../../hooks/use-device-size";

import NestedAccordion from "../../../components/Accordion/nestedAccordion";
import { accrodion_array } from "../../../static-data/faqAccordionData";
import bannerImg from "../../../images/resources/hero-resources-overview.webp";
import bannerImgMobile from "../../../images/resources/hero-resources-overview-mobile.webp";
import SocialIconBlock from "../../../components/socialIconBlock";
import "./frequently-asked-questions.scss";
import { StaticImage } from "gatsby-plugin-image";
import InnerBanner from "../../../components/InnerBanner";
const FrequentlyAskedQuestions = () => {
  // const deviceSizes = useDeviceSize();

  return (
    <HcpLayout pageClass="hcp-frequently-asked-questions">
      <InnerBanner
        topHeading="Frequently Asked Questions"
        bannerClass="resources"
        bannerHeroImg={bannerImg}
        bannerHeroImgMobile={bannerImgMobile}
        bannerImgAlt="Chris, a real person with narcolepsy with cataplexy taking WAKIX, sitting with his phone"
        heroNameClass="on-right"
        heroNameImg={
          <StaticImage
            src="../../../images/resources/hero-resources-overview-name.webp"
            alt="Chris - Living with narcolepsy Taking WAKIX since 2021"
            width={231}
            height={120}
            quality={95}
            layout="fixed"
            placeholder="none"
            style={{ transition: "opacity 0.5s" }}
            imgStyle={{ opacity: 1 }}
          />
        }
      />

      <section className="inner-first no-pb">
        <div className="centered-content">
          <div className="page-heading ph-btm">
            <h1>WAKIX Frequently Asked Questions</h1>
            <p className="h2">
              Find the answers to your questions about WAKIX and narcolepsy
            </p>
          </div>
        </div>
      </section>

      <section className="frequently-asked-questions no-pt">
        <div className="centered-content">
          <div className="accordion__wrapper">
            {accrodion_array.map((data, index) => {
              return (
                <>
                  <NestedAccordion
                    data={data}
                    customTagText={data.customTagTitle}
                  />
                </>
              );
            })}
          </div>
        </div>
      </section>
      <SocialIconBlock />
    </HcpLayout>
  );
};

export default FrequentlyAskedQuestions;

export const Head = () => (
  <Seo
    title="Frequently Asked Questions | WAKIX® (pitolisant)"
    description="See answers to frequently asked questions about WAKIX."
    keywords="WAKIX FAQ, WAKIX frequently asked questions, pitolisant FAQ, pitolisant frequently asked questions, WAKIX questions, pitolisant questions, questions about WAKIX, questions about pitolisant, FAQ"
  />
);
